import React from "react";
import { Helmet } from "react-helmet-async";
import partner1 from "../assets/svk.jpg";
import partner2 from "../assets/vattenfall.png";
import partner3 from "../assets/hitachi-red.jpg";
import partner4 from "../assets/enercon.png";
import partner5 from "../assets/rollsroyce.jpg";

const Testimonials = () => {
  const testimonials = [
    {
      name: "Svenska Kraftnät",
      feedback:
        "Pifas Sweden AB has contributed with safety and quality on our projects that included transmission line renewal, new and expansion of substations in central and northern Sweden. ",
      image: partner1,
    },
    {
      name: "Vattenfall",
      feedback:
        "We have been working with Vattenfall on coal extraction projects in eastern Germany. Pifas Sweden AB has ensured that service personnel can work safely at height at Vattenfall's crane facilities at Schwarze Pumpe.",
      image: partner2,
    },
    {
      name: "Hitachi Power Europe",
      feedback:
        "Hitachi Power Europe designs and builds fossil-fuelled power plants. The plant builder also supplies key components such as steam generators, environmental equipment, turbines and pulverisers. The role of HSE Safety coordinator at the CHP 400 MW Öresund plant in Malmö was done in good co-operation with the client E-On.",
      image: partner3,
    },
    {
      name: "Enercon",
      feedback:
        "His safety management skills and clear communication made our collaboration productive. Stefan’s work ethic and professionalism were exceptional.",
      image: partner4,
    },
    {
      name: "Rolls Royce Marine",
      feedback:
        "HSE Management is an interesting role in a company such as Rolls Royce Marine in Norway. Pifas Sweden AB has also taken on the HSE Manager role on project assignments where the task has been to monitor and follow the company's ongoing projects around the world.",
      image: partner5,
    },
  ];

  return (
    <>
      <Helmet>
        <title>Pifas Sweden AB | Recensioner från tidigare samarbeten</title>
        <meta
          name="description"
          content="Läs omdömen från tidigare samarbeten med Pifas Sweden AB och hur vi har hjälpt företag som SVK, Vattenfall, Vestas och fler att förbättra säkerhet och kvalitet i sina projekt."
        />
        <meta
          name="keywords"
          content="Pifas Sweden AB, kundrecensioner, samarbeten, byggindustrin, säkerhet och kvalitet, SVK, Vattenfall, Vestas, Enercon, Rolls Royce Marine, BAS-P, BAS-U, HSE"
        />
      </Helmet>

      <section className="bg-white py-16" id="testimonials">
        <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8 lg:py-16">
          <h2 className="text-center text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Recensioner från tidigare samarbetspartners
          </h2>

          <div className="mt-8 grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-8">
            {testimonials.map((testimonial, index) => (
              <blockquote
                key={index}
                className="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8"
              >
                <div className="flex items-center gap-4">
                  <img
                    alt={`${testimonial.name} - Partner to Pifas Sweden AB`}
                    src={testimonial.image}
                    className="h-14 w-14 object-contain border border-gray-300 shadow-sm rounded-md"
                  />
                  <div>
                    <div className="flex justify-center gap-0.5 text-blue-500">
                      {[...Array(5)].map((_, i) => (
                        <svg
                          key={i}
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                      ))}
                    </div>

                    <p className="mt-0.5 text-lg font-medium text-gray-900">
                      {testimonial.name}
                    </p>
                  </div>
                </div>

                <p className="mt-4 text-gray-700">{testimonial.feedback}</p>
              </blockquote>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonials;
