// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage"; // Import Firebase Storage

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBwGBGJekE65Kc3pWpRX9YM8NJ7JjprBYU",
  authDomain: "pifas-ab.firebaseapp.com",
  projectId: "pifas-ab",
  storageBucket: "pifas-ab.appspot.com",
  messagingSenderId: "319758281859",
  appId: "1:319758281859:web:44fe52f58532a1ace17fb6",
  measurementId: "G-4J83YQHCNL",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app); // Initialize and configure Firebase Storage

// Export storage so it can be imported in other parts of the app
export { storage };
