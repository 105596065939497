import React, { useState } from "react";
import { Helmet } from "react-helmet-async"; // For SEO metadata
import emailjs from "emailjs-com";
import { storage } from "../firebase/firebaseConfig";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const ContactUs = () => {
  const [userType, setUserType] = useState(null); // Track Företag or Privatperson
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    cv: null,
    personalLetter: null,
  });
  const [cvName, setCvName] = useState("Ingen fil vald");
  const [personalLetterName, setPersonalLetterName] =
    useState("Ingen fil vald");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [uploading, setUploading] = useState(false);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      if (name === "cv") {
        setFormData({ ...formData, cv: files[0] });
        setCvName(files[0].name);
      } else if (name === "personalLetter") {
        setFormData({ ...formData, personalLetter: files[0] });
        setPersonalLetterName(files[0].name);
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      userType === "Privatperson" &&
      (formData.cv || formData.personalLetter)
    ) {
      setUploading(true);
      const uploadPromises = [];

      if (formData.cv) {
        const cvRef = ref(storage, `curriculums/${formData.cv.name}`);
        const uploadTaskCV = uploadBytesResumable(cvRef, formData.cv);
        uploadPromises.push(
          new Promise((resolve, reject) => {
            uploadTaskCV.on(
              "state_changed",
              null,
              (error) => reject(error),
              async () => {
                const cvUrl = await getDownloadURL(uploadTaskCV.snapshot.ref);
                resolve({ cvUrl });
              }
            );
          })
        );
      }

      if (formData.personalLetter) {
        const letterRef = ref(
          storage,
          `personal_letters/${formData.personalLetter.name}`
        );
        const uploadTaskLetter = uploadBytesResumable(
          letterRef,
          formData.personalLetter
        );
        uploadPromises.push(
          new Promise((resolve, reject) => {
            uploadTaskLetter.on(
              "state_changed",
              null,
              (error) => reject(error),
              async () => {
                const letterUrl = await getDownloadURL(
                  uploadTaskLetter.snapshot.ref
                );
                resolve({ letterUrl });
              }
            );
          })
        );
      }

      try {
        const urls = await Promise.all(uploadPromises);
        const cvUrl = urls.find((url) => url.cvUrl)?.cvUrl || "";
        const letterUrl = urls.find((url) => url.letterUrl)?.letterUrl || "";
        sendEmail(cvUrl, letterUrl);
        setUploading(false);
      } catch (error) {
        console.error("Upload failed:", error);
        setIsError(true);
        setUploading(false);
      }
    } else {
      sendEmail(null, null);
    }
  };

  const sendEmail = (cvUrl, letterUrl) => {
    emailjs
      .send(
        "service_1yi4y45",
        "template_6c4ojvb",
        {
          from_name: formData.name,
          from_email: formData.email,
          message: `${formData.message}${
            cvUrl ? `\n\nCV Link: ${cvUrl}` : ""
          }${letterUrl ? `\n\nPersonal Letter Link: ${letterUrl}` : ""}`,
        },
        "gcbQiK1_b0K3YGzYg"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setIsSubmitted(true);
          setIsError(false);
        },
        (error) => {
          console.error("FAILED...", error);
          setIsError(true);
        }
      );
  };

  const handleBackClick = () => {
    setUserType(null); // Reset user type to show the options again
    setFormData({
      name: "",
      email: "",
      message: "",
      cv: null,
      personalLetter: null,
    });
    setCvName("Ingen fil vald");
    setPersonalLetterName("Ingen fil vald");
    setIsSubmitted(false);
    setIsError(false);
  };

  return (
    <>
      {/* SEO Metadata */}
      <Helmet>
        <title>Kontakta oss - Pifas Sweden AB</title>
        <meta
          name="description"
          content="Kontakta Pifas Sweden AB för frågor och hjälp med våra konsult- och rekryteringstjänster. Vi hjälper dig med säkerhet, projektledning och mer!"
        />
        <meta
          name="keywords"
          content="kontakt pifas, rekrytering byggindustri, projektledning, säkerhet, BAS-P, BAS-U, HSE"
        />
      </Helmet>

      {/* Contact Us Section */}
      <section
        id="contact-section"
        className="container mx-auto px-4 sm:px-6 md:px-8 mt-8 mb-12 flex justify-center"
      >
        <div className="w-full max-w-md rounded-xl border border-gray-200 bg-gray-50 p-6 shadow-md">
          <h2 className="text-2xl font-bold text-center text-gray-900 mb-6">
            Kontakta oss
          </h2>

          {!userType ? (
            <div className="flex justify-around mb-6">
              <button
                onClick={() => setUserType("Företag")}
                className="bg-blue-600 text-white px-4 py-2 rounded-md shadow hover:bg-blue-700"
              >
                Företag
              </button>
              <button
                onClick={() => setUserType("Privatperson")}
                className="bg-cyan-500 text-white px-4 py-2 rounded-md shadow hover:bg-cyan-600"
              >
                Privatperson
              </button>
            </div>
          ) : isSubmitted ? (
            <p className="text-blue-600 text-center">
              Tack för ditt meddelande! Vi kommer att kontakta dig snart.
            </p>
          ) : isError ? (
            <p className="text-red-600 text-center">
              Något gick fel. Vänligen försök igen.
            </p>
          ) : (
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-600"
                >
                  Namn
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="mt-1 w-full p-2 border border-gray-300 rounded-md focus:border-blue-500 focus:outline-none"
                />
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-600"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="mt-1 w-full p-2 border border-gray-300 rounded-md focus:border-blue-500 focus:outline-none"
                />
              </div>

              <div>
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-600"
                >
                  Meddelande
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  rows="4"
                  className="mt-1 w-full p-2 border border-gray-300 rounded-md focus:border-blue-500 focus:outline-none"
                />
              </div>

              {userType === "Privatperson" && (
                <>
                  <div>
                    <label className="block text-sm font-medium text-gray-600">
                      Ladda upp CV
                    </label>
                    <input
                      type="file"
                      id="cv"
                      name="cv"
                      onChange={handleChange}
                      className="hidden"
                    />
                    <label
                      htmlFor="cv"
                      className="inline-block mt-2 cursor-pointer rounded-md bg-blue-500 px-4 py-2 text-white font-semibold shadow hover:bg-blue-600 focus:outline-none"
                    >
                      Välj fil
                    </label>
                    <span className="mt-2 ml-3 text-sm text-gray-600">
                      {cvName}
                    </span>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-600">
                      Ladda upp Personligt brev
                    </label>
                    <input
                      type="file"
                      id="personalLetter"
                      name="personalLetter"
                      onChange={handleChange}
                      className="hidden"
                    />
                    <label
                      htmlFor="personalLetter"
                      className="inline-block mt-2 cursor-pointer rounded-md bg-blue-500 px-4 py-2 text-white font-semibold shadow hover:bg-blue-600 focus:outline-none"
                    >
                      Välj fil
                    </label>
                    <span className="mt-2 ml-3 text-sm text-gray-600">
                      {personalLetterName}
                    </span>
                  </div>
                </>
              )}

              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={handleBackClick}
                  className="py-2 px-4 bg-gray-300 text-gray-800 font-semibold rounded-md shadow hover:bg-gray-400 focus:outline-none"
                >
                  Tillbaka
                </button>
                <button
                  type="submit"
                  className="py-2 px-4 bg-blue-500 text-white font-semibold rounded-md shadow hover:bg-blue-600 focus:outline-none"
                  disabled={uploading}
                >
                  {uploading ? "Laddar upp..." : "Skicka Meddelande"}
                </button>
              </div>
            </form>
          )}
        </div>
      </section>
    </>
  );
};

export default ContactUs;
