import React from "react";
import { Helmet } from "react-helmet-async";
import ConstructionImage from "../assets/construction.png";

const About = () => {
  return (
    <>
      {/* SEO Metadata */}
      <Helmet>
        <title>
          Om Pifas Sweden AB - Konsult- och Rekryteringstjänster i Bygg och
          Industri
        </title>
        <meta
          name="description"
          content="Lär dig mer om Pifas Sweden AB, specialister inom konsult- och rekryteringstjänster för bygg- och industribranschen. Expertis inom arbetsmiljö, säkerhet och projektledning."
        />
        <meta
          name="keywords"
          content="rekrytering byggindustri, arbetsmiljöingenjör, säkerhetskonsult, projektledning bygg, BAS-U, BAS-P, HSEQ"
        />
        <meta
          property="og:title"
          content="Om Pifas Sweden AB - Konsult- och Rekryteringstjänster"
        />
        <meta
          property="og:description"
          content="Pifas Sweden AB är specialister inom bygg- och industribranschen, som erbjuder lösningar inom arbetsmiljö, säkerhet och projektledning."
        />
        <meta
          property="og:image"
          content="https://pifasab.com/assets/pifas-ab-CNX5U1uB.jpg"
        />
        <meta property="og:url" content="https://pifasab.com" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Om Pifas Sweden AB - Konsult- och Rekryteringstjänster"
        />
        <meta
          name="twitter:description"
          content="Lär dig mer om Pifas Sweden AB och våra tjänster inom arbetsmiljö, säkerhet och projektledning för bygg- och industribranschen."
        />
        <meta
          name="twitter:image"
          content="https://pifasab.com/assets/pifas-ab-CNX5U1uB.jpg"
        />
      </Helmet>

      {/* About Section */}
      <div
        id="about-section"
        className="flex flex-col sm:flex-row items-center max-w-screen-xl mx-auto py-16 px-4 sm:px-8 lg:px-16"
      >
        <div className="sm:w-1/2 p-5 sm:p-10 flex justify-center">
          <img
            src={ConstructionImage}
            alt="Under Construction"
            className="rounded-md max-w-full h-auto"
          />
        </div>
        <div className="sm:w-1/2 p-5 sm:p-10 text-center sm:text-left">
          <span className="text-blue-500 text-sm font-semibold uppercase tracking-wide">
            Om oss
          </span>
          <h2 className="mt-4 mb-6 text-3xl sm:text-4xl font-extrabold text-gray-900">
            Lär er mer <span className="text-blue-500">om vår verksamhet.</span>
          </h2>
          <p className="text-gray-600 leading-relaxed text-base sm:text-lg">
            Pifas Sweden AB är specialiserat på konsult- och
            rekryteringstjänster inom bygg- och industribranschen. Med över 30
            års erfarenhet fokuserar vi på att leverera expertkompetens inom
            arbetsmiljö, säkerhetsledning och projektledning. Vårt team består
            av erfarna konsulter som har arbetat med stora projekt, där säkerhet
            och hållbarhet är centrala krav. Vi kopplar samman företag med
            kvalificerade experter inom BAS-U och BAS-P, HSEQ-ledning, samt
            operativa roller för att säkerställa att projekt genomförs säkert,
            effektivt och enligt gällande regler och standarder. Genom att
            förstå våra kunders unika behov, erbjuder vi skräddarsydda lösningar
            som stärker både kvalitet och säkerhet i varje projekt.{" "}
            <span className="text-blue-500">Kontakta oss idag!</span>
          </p>
        </div>
      </div>
    </>
  );
};

export default About;
