import React from "react";
import StebanImage from "../assets/steban-bild.jpeg"; // Adjust the path as necessary
import StefanImage from "../assets/stefan-bild.jpg"; // Adjust the path as necessary

const Cards = () => {
  return (
    <div className="container mx-auto px-4 mt-8">
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
        {/* Card for Stefan Sahlén */}
        <div className="flex justify-center">
          <article className="w-full max-w-md rounded-xl border border-gray-200 bg-gray-50 p-4 shadow-sm">
            <div className="flex items-center gap-4">
              <img
                alt="HSE, BAS-P BAS-U, konsult"
                src={StefanImage}
                className="w-12 h-12 rounded-full object-cover"
              />

              <div>
                <h3 className="text-lg font-medium text-gray-900">
                  Stefan Sahlén
                </h3>
                <div className="flow-root">
                  <ul className="-m-1 flex flex-wrap">
                    <li className="p-1 leading-none">
                      <a
                        href="https://www.linkedin.com/in/stefan-sahl%C3%A9n-a6374a19/"
                        className="text-xs font-medium text-blue-500"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        LinkedIn
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Single Job Experience Section */}
            <ul className="mt-4 space-y-2">
              <li>
                <a
                  href="https://www.linkedin.com/in/stefan-sahl%C3%A9n-a6374a19/"
                  className="block rounded-lg border border-gray-200 p-4 hover:border-blue-500"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong className="font-medium text-gray-900">
                    Health, Safety and Environment Management
                    <span className="block text-blue-500 font-semibold text-sm mt-1">
                      @ Pifas Sweden AB (2013 - present)
                    </span>
                  </strong>
                  <p className="mt-1 text-xs font-medium text-gray-700">
                    HSE Manager
                    <br />
                    HSE Coordination BAS-P and BAS-U
                    <br />
                    HSE Supervision
                  </p>
                </a>
              </li>
            </ul>

            {/* Contact Details */}
            <ul className="mt-6 space-y-2">
              <li>
                <a
                  href="mailto:stefan.sahlen@pifasab.com"
                  className="block rounded-lg border border-gray-200 p-4 hover:border-blue-500"
                >
                  <strong className="font-medium text-gray-900">
                    Email
                    <span className="block text-blue-500 font-semibold text-sm mt-1">
                      stefan.sahlen@pifasab.com
                    </span>
                  </strong>
                  <p className="mt-1 text-xs font-medium text-gray-700">
                    Kontakta Stefan via email för konsultationer eller andra
                    förfrågningar.
                  </p>
                </a>
              </li>
              <li>
                <a
                  href="tel:+46762130788"
                  className="block rounded-lg border border-gray-200 p-4 hover:border-blue-500"
                >
                  <strong className="font-medium text-gray-900">
                    Telefon
                    <span className="block text-blue-500 font-semibold text-sm mt-1">
                      +46762130788
                    </span>
                  </strong>
                  <p className="mt-1 text-xs font-medium text-gray-700">
                    Tillgänglig för samtal under kontorstider för konsultationer
                    eller andra förfrågningar.
                  </p>
                </a>
              </li>
            </ul>
          </article>
        </div>

        {/* Card for Steban Sahlén */}
        <div className="flex justify-center">
          <article className="w-full max-w-md rounded-xl border border-gray-200 bg-gray-50 p-4 shadow-sm">
            <div className="flex items-center gap-4">
              <img
                alt="HSE, BAS-P BAS-U, konsult"
                src={StebanImage}
                className="w-12 h-12 rounded-full object-cover"
              />

              <div>
                <h3 className="text-lg font-medium text-gray-900">
                  Steban Sahlén
                </h3>
                <div className="flow-root">
                  <ul className="-m-1 flex flex-wrap">
                    <li className="p-1 leading-none">
                      <a
                        href="https://www.linkedin.com/in/steban-sahlen-b3190482/"
                        className="text-xs font-medium text-blue-500"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        LinkedIn
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Single Job Experience Section */}
            <ul className="mt-4 space-y-2">
              <li>
                <a
                  href="https://www.linkedin.com/in/steban-sahlen-b3190482/"
                  className="block rounded-lg border border-gray-200 p-4 hover:border-blue-500"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong className="font-medium text-gray-900">
                    Ekonomi/HR ansvarig
                    <span className="block text-blue-500 font-semibold text-sm mt-1">
                      @ Pifas Sweden AB
                    </span>
                  </strong>
                  <p className="mt-1 text-xs font-medium text-gray-700">
                    Managed day-to-day operations, optimized processes, and led
                    initiatives to drive business performance.
                  </p>
                </a>
              </li>
            </ul>

            {/* Contact Details */}
            <ul className="mt-6 space-y-2">
              <li>
                <a
                  href="mailto:mail@pifasab.com"
                  className="block rounded-lg border border-gray-200 p-4 hover:border-blue-500"
                >
                  <strong className="font-medium text-gray-900">
                    Email
                    <span className="block text-blue-500 font-semibold text-sm mt-1">
                      steban.sahlen@pifasab.com
                    </span>
                  </strong>
                  <p className="mt-1 text-xs font-medium text-gray-700">
                    Kontakta Steban via email för konsultationer eller andra
                    förfrågningar.
                  </p>
                </a>
              </li>
              <li>
                <a
                  href="tel:+46762130788"
                  className="block rounded-lg border border-gray-200 p-4 hover:border-blue-500"
                >
                  <strong className="font-medium text-gray-900">
                    Telefon
                    <span className="block text-blue-500 font-semibold text-sm mt-1">
                      +46762130788
                    </span>
                  </strong>
                  <p className="mt-1 text-xs font-medium text-gray-700">
                    Tillgänglig för samtal under kontorstider för konsultationer
                    eller andra förfrågningar.
                  </p>
                </a>
              </li>
            </ul>
          </article>
        </div>
      </div>
    </div>
  );
};

export default Cards;
