import React from "react";
import { Helmet } from "react-helmet-async";
import BackgroundImage from "../assets/herobackground.jpg";
import partner1 from "../assets/svk.jpg";
import partner2 from "../assets/vattenfall.png";
import partner3 from "../assets/hitachi-red.jpg";
import partner4 from "../assets/enercon.png";
import partner5 from "../assets/rollsroyce.jpg";

const Hero = () => {
  const handleSmoothScroll = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Helmet>
        <title>Pifas Sweden AB - BAS-P BAS-U, miljösamordning</title>
        <meta
          name="description"
          content="Pifas Sweden AB, BAS-P BAS-U, miljösamordning, HSE"
        />
        <meta
          name="keywords"
          content="Pifas Sweden AB, byggindustrin, HSE, BAS-P, BAS-U, profilering, kontraktförhandling, HSE"
        />
        <meta
          property="og:title"
          content="Pifas Sweden AB - Kvalitet och Säkerhet inom Byggindustrin"
        />
        <meta
          property="og:description"
          content="Med 30 års erfarenhet inom HSE, erbjuder Pifas Sweden AB konsulttjänster inom projektledning, profilering och kontraktförhandling."
        />
        <meta property="og:image" content={BackgroundImage} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://pifasab.com/" />
      </Helmet>

      <section
        className="relative bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${BackgroundImage})`,
        }}
      >
        <div className="absolute inset-0 bg-white/75 sm:bg-transparent sm:from-white/95 sm:to-white/25 sm:bg-gradient-to-r"></div>

        <div className="relative mx-auto max-w-screen-xl px-4 py-32 sm:px-6 lg:flex lg:h-screen lg:items-center lg:px-8">
          <div className="max-w-xl text-center sm:text-left">
            <h1 className="text-3xl font-extrabold sm:text-5xl leading-snug">
              Kvalitet och säkerhet i varje steg –
              <span className="block mt-1 font-extrabold text-blue-900">
                er framgång.
              </span>
            </h1>

            <p className="mt-6 max-w-lg sm:text-xl">
              Vi hjälper byggherrar med Byggarbetsmiljösamordningen, BAS-P och
              BAS-U, enligt de nya regelstrukturerna som gäller från och med 1
              januari 2025. Kontakta oss idag!
            </p>

            <div className="mt-8 flex flex-wrap gap-4 text-center">
              <button
                onClick={() => handleSmoothScroll("contact-section")}
                className="block w-full rounded bg-blue-800 px-12 py-3 text-sm font-medium text-white shadow hover:bg-blue-700 focus:outline-none focus:ring active:bg-blue-800 sm:w-auto"
              >
                Kontakta oss
              </button>

              <button
                onClick={() => handleSmoothScroll("about-section")}
                className="block w-full rounded bg-white px-12 py-3 text-sm font-medium text-blue-800 shadow hover:text-blue-700 focus:outline-none focus:ring active:text-green-800 sm:w-auto"
              >
                Om oss
              </button>
            </div>

            {/* Subheading and Partner Images */}
            <h2 className="mt-12 text-lg font-semibold text-gray-800">
              Tidigare uppdragsgivare
            </h2>
            <div className="mt-4 flex justify-center sm:justify-start space-x-4">
              {/* Partner Images with Consistent Aspect Ratio */}
              <img
                src={partner1}
                alt="Svenska kraftnät - Partner to Pifas Sweden AB"
                className="w-12 h-12 object-contain border border-gray-200 shadow-sm"
              />
              <img
                src={partner2}
                alt="Vattenfall - Partner to Pifas Sweden AB"
                className="w-12 h-12 object-contain border border-gray-200 shadow-sm"
              />
              <img
                src={partner3}
                alt="Vestas - Partner to Pifas Sweden AB"
                className="w-12 h-12 object-contain border border-gray-200 shadow-sm"
              />
              <img
                src={partner4}
                alt="Enercon - Partner to Pifas Sweden AB"
                className="w-12 h-12 object-contain border border-gray-200 shadow-sm"
              />
              <img
                src={partner5}
                alt="Rolls-Royce - Trusted Partner"
                className="w-12 h-12 object-contain border border-gray-200 shadow-sm"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
