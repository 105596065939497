import React from "react";
import { Helmet } from "react-helmet-async";
import PIFAS from "../assets/pifas-ab.jpg"; // Adjust the path if necessary

const Footer = () => {
  const handleSmoothScroll = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <footer className="bg-gray-100 text-gray-700">
      {/* Helmet for SEO Meta Tags */}
      <Helmet>
        <title>
          Pifas Sweden AB | Specialistkonsulter & Rekrytering inom Bygg och
          Industri
        </title>
        <meta
          name="description"
          content="Pifas Sweden AB är ett arbetsmiljösamordningsföretag specialiserat på att förmedla konsulter inom bygg och industri. Vi erbjuder expertis inom projektledning, säkerhet och arbetsmiljö."
        />
        <meta
          name="keywords"
          content="rekrytering, byggindustri, arbetsmiljöingenjör, säkerhetskonsult, projektledning bygg, HSEQ, BAS-U, BAS-P, miljökonsult, Pifas Sweden AB"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Meta Tags */}
        <meta
          property="og:title"
          content="Pifas Sweden AB | Specialistkonsulter inom Bygg och Industri"
        />
        <meta
          property="og:description"
          content="Pifas Sweden AB hjälper bygg- och industriföretag att hitta kvalificerade konsulter inom arbetsmiljö och säkerhet."
        />
        <meta
          property="og:image"
          content="hhttps://pifasab.com/assets/pifas-ab-CNX5U1uB.jpg"
        />
        <meta property="og:url" content="https://pifas.se" />
        <meta property="og:type" content="website" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Pifas Sweden AB - Konsulter för Bygg och Industri"
        />
        <meta
          name="twitter:description"
          content="Pifas Sweden AB rekryterar specialister inom projektledning, arbetsmiljö och säkerhet för bygg och industri."
        />
        <meta
          name="twitter:image"
          content="https://pifasab.com/assets/pifas-ab-CNX5U1uB.jpg"
        />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Pifas Sweden AB",
              "url": "https://pifas.se",
              "logo": "https://pifasab.com/assets/pifas-ab-CNX5U1uB.jpg",
              "sameAs": [
                "https://www.facebook.com/pifasab",
                "https://www.instagram.com/pifasab",
                "https://www.linkedin.com/company/pifasab"
              ],
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+46-40-123456",
                "contactType": "Customer Service",
                "areaServed": "SE",
                "availableLanguage": ["Swedish", "English"]
              },
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Hyllie Stationstorg 31",
                "addressLocality": "Malmö",
                "postalCode": "215 32",
                "addressCountry": "SE"
              },
              "description": "Pifas Sweden AB är specialister inom konsultförmedling och rekrytering för bygg- och industribranschen i Sverige."
            }
          `}
        </script>
      </Helmet>

      {/* Footer Content */}
      <div className="relative mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8 lg:pt-24">
        <div className="absolute end-4 top-4 sm:end-6 sm:top-6 lg:end-8 lg:top-8">
          <button
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            className="inline-block rounded-full bg-blue-600 p-2 text-white shadow transition hover:bg-blue-500 sm:p-3 lg:p-4"
          >
            <span className="sr-only">Back to top</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>

        <div className="lg:flex lg:items-end lg:justify-between">
          <div>
            <div className="flex justify-center text-blue-600 lg:justify-start">
              <img
                src={PIFAS}
                alt="PIFAS Logo"
                style={{ height: "100px", width: "100px" }}
              />
            </div>
            <p className="mx-auto mt-6 max-w-md text-center leading-relaxed text-gray-500 lg:text-left">
              Pifas Sweden AB är ett privat arbetsmiljösamordningsföretag som
              söker konsulter att handplocka för olika uppdrag. Vi är en del av
              din framgång inom bygg och industri.
            </p>
            <p className="mt-6 text-center text-sm text-gray-500 lg:text-left">
              This webpage was created by{" "}
              <a
                href="https://www.linkedin.com/in/christian-c-meza-05165118a?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3B2ScJdwKcTk6JdlVc3zCaNQ%3D%3D"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                Christian Meza
              </a>
            </p>
          </div>

          {/* Additional Links */}
          <ul className="mt-12 flex flex-wrap justify-center gap-6 md:gap-8 lg:mt-0 lg:justify-end lg:gap-12">
            <li>
              <button
                onClick={() => handleSmoothScroll("about-section")}
                className="text-gray-700 transition hover:text-gray-700/75"
              >
                About
              </button>
            </li>
            <li>
              <button
                onClick={() => handleSmoothScroll("services")}
                className="text-gray-700 transition hover:text-gray-700/75"
              >
                Services
              </button>
            </li>
            <li>
              <button
                onClick={() => handleSmoothScroll("testimonials")}
                className="text-gray-700 transition hover:text-gray-700/75"
              >
                Projects
              </button>
            </li>
          </ul>
        </div>

        {/* Discreet Legal Notice */}
        <div className="mt-12 lg:mt-16 text-xs text-gray-500">
          <p className="text-center lg:text-right">
            Pifas Sweden AB, Hyllie Stationstorg 31, SE-215 32 Malmö
            <br />
            Telephone: +46 (0) 762 130 788 | E-mail:{" "}
            <a
              href="mailto:mail@pifasab.com"
              className="text-blue-600 hover:underline"
            >
              mail@pifasab.com
            </a>
          </p>
          <p className="mt-4 text-center lg:text-right">
            Org.Nr.: 556966-7180 | VAT Nr.: SE556966718001
            <br />
            <a
              href="https://aboutcookies.org"
              className="text-blue-600 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cookie Policy
            </a>{" "}
            |{" "}
            <a
              href="https://www.youronlinechoices.eu"
              className="text-blue-600 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              GDPR Info
            </a>
          </p>
        </div>

        <p className="mt-12 text-center text-sm text-gray-500 lg:text-right">
          Copyright &copy; 2024 Pifas Sweden AB. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
