import React from "react";
import { Helmet } from "react-helmet-async";
import styles from "./Services.module.css";

const Services = () => {
  const services = [
    {
      title: "Jobb för konsulter",
      description: "Lägg upp dina annonser för erfarna experter.",
    },
    {
      title: "Profilering",
      description: "Vi profilerar konsulter och matchar dem med dina behov.",
    },
    {
      title: "Utbildning",
      description: "Frågor inom byggsamordning.",
    },
    {
      title: "Konsultsupport",
      description: "Stöd och rådgivning för konsulter genom hela projektet.",
    },
  ];

  const handleScrollToContact = () => {
    const contactSection = document.getElementById("contact-section");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      {/* SEO Metadata */}
      <Helmet>
        <title>Pifas Sweden AB - Våra tjänster inom Bygg och Industri</title>
        <meta
          name="description"
          content="Utforska våra tjänster, inklusive rekrytering, profilering, utbildning och konsultsupport för bygg- och industribranschen."
        />
        <meta
          name="keywords"
          content="rekrytering byggindustri, konsulttjänster, profilering, utbildning byggsamordning, konsultsupport"
        />
        <meta
          property="og:title"
          content="Pifas Sweden AB - Våra tjänster inom HSE, BAS-P BAS-U"
        />
        <meta
          property="og:description"
          content="Pifas Sweden AB erbjuder tjänster som matchar dina behov inom bygg- och industribranschen. Vi tillhandahåller experter för rekrytering, profilering och mer."
        />
        <meta
          property="og:image"
          content="https://pifasab.com/assets/pifas-ab-CNX5U1uB.jpg"
        />
        <meta property="og:url" content="https://pifasab.com/services" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Pifas Sweden AB - Våra tjänster inom Bygg och Industri"
        />
        <meta
          name="twitter:description"
          content="Utforska våra konsulttjänster för bygg och industri, inklusive arbetsmiljö, säkerhet och projektledning."
        />
        <meta
          name="twitter:image"
          content="https://pifasab.com/assets/pifas-ab-CNX5U1uB.jpg"
        />
      </Helmet>

      {/* Services Section */}
      <section className={`${styles.services} flex items-center`} id="services">
        <div className="max-w-screen-xl mx-auto px-4 py-4 sm:px-6 sm:py-6 lg:px-8 lg:py-8">
          <div className="grid grid-cols-1 gap-y-8 lg:grid-cols-2 lg:items-center lg:gap-x-16">
            <div className="mx-auto max-w-lg text-center lg:mx-0 lg:text-left">
              <h2 className="text-3xl font-bold sm:text-4xl">Våra tjänster</h2>
              <p className="mt-4 text-gray-600">
                Utforska våra olika tjänster som är designade för att passa dina
                behov.
              </p>
              <button
                onClick={handleScrollToContact}
                className="mt-8 inline-block rounded bg-blue-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-blue-700 focus:outline-none focus:ring focus:ring-yellow-400"
              >
                Kontakta oss
              </button>
            </div>

            <div className="grid grid-cols-2 gap-4 sm:grid-cols-3">
              {services.map((service, index) => (
                <div
                  key={index}
                  className="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
                >
                  <span className="inline-block rounded-lg bg-gray-50 p-3">
                    <svg
                      className="h-6 w-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
                      <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"></path>
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 14l9-5-9-5-9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                      ></path>
                    </svg>
                  </span>

                  <h2 className="mt-2 font-bold">{service.title}</h2>
                  <p className="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600">
                    {service.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
