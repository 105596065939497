import React from "react";
import { HelmetProvider } from "react-helmet-async";
import CookieConsent from "react-cookie-consent";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Cards from "./components/Cards";
import Services from "./components/Services";
import Testimonials from "./components/Testimonials";
import Footer from "./components/Footer";
import ContactUs from "./components/ContactUs";
import About from "./components/About";
import PrivacyPolicy from "./components/PrivacyPolicy";

const App = () => {
  return (
    <HelmetProvider>
      <div className="font-sans">
        <Header />
        <Hero />
        <Cards />
        <Testimonials />
        <Services />
        <About />
        <ContactUs />
        <PrivacyPolicy />
        <Footer />
        {/* GDPR Cookie Consent */}
        <CookieConsent
          location="bottom"
          buttonText="I Accept"
          cookieName="gdprConsent"
          style={{ background: "rgba(0, 0, 0, 0.9)" }}
          buttonStyle={{
            color: "#ffffff",
            backgroundColor: "#4CAF50",
            fontSize: "14px",
            borderRadius: "4px",
          }}
          expires={365}
          className="p-4 text-sm text-white flex flex-col md:flex-row items-center justify-between"
        >
          Den här webbplatsen använder kakor för att förbättra
          användarupplevelsen.{" "}
          <a
            href="#privacy-policy"
            className="text-blue-400 hover:underline ml-2"
          >
            Läs mer
          </a>
        </CookieConsent>
      </div>
    </HelmetProvider>
  );
};

export default App;
