import React, { useState } from "react";

const PrivacyPolicy = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      id="privacy-policy"
      className="bg-white border border-gray-200 shadow-md rounded-lg p-6 mx-auto max-w-4xl my-10"
    >
      <h1
        className="text-3xl font-semibold text-gray-900 text-center mb-4 cursor-pointer hover:text-blue-500 transition"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        Sekretesspolicy
      </h1>
      {!isExpanded ? (
        <p
          className="text-gray-700 text-center cursor-pointer hover:underline"
          onClick={() => setIsExpanded(true)}
        >
          Klicka här för att läsa hela sekretesspolicyn
        </p>
      ) : (
        <div className="space-y-6">
          <p className="text-gray-800 leading-relaxed">
            Din integritet är viktig för oss. Denna sekretesspolicy beskriver
            hur vi samlar in, använder och skyddar dina personuppgifter i
            enlighet med gällande lagar.
          </p>

          <div className="space-y-4">
            <h2 className="text-xl font-bold text-gray-900">
              1. Data vi samlar in
            </h2>
            <ul className="list-disc pl-5 text-gray-700 space-y-2">
              <li>Uppladdade CV:n (Curriculum Vitae).</li>
              <li>Personliga brev som skickas via vårt kontaktformulär.</li>
              <li>
                Grundläggande användarinformation, såsom e-postadresser,
                insamlad via formulär.
              </li>
            </ul>
          </div>

          <div className="space-y-4">
            <h2 className="text-xl font-bold text-gray-900">
              2. Hur vi använder din data
            </h2>
            <p className="text-gray-800 leading-relaxed">
              Vi använder din data enbart för att underlätta kommunikation och
              matchningstjänster för jobb. Specifikt:
            </p>
            <ul className="list-disc pl-5 text-gray-700 space-y-2">
              <li>
                CV:n och personliga brev granskas för att matcha kandidater med
                potentiella möjligheter.
              </li>
              <li>
                E-postadresser kan användas för att kontakta dig angående
                inskickade ansökningar.
              </li>
              <li>Data lagras säkert i Firebase.</li>
            </ul>
          </div>

          <div className="space-y-4">
            <h2 className="text-xl font-bold text-gray-900">
              3. Lagring och säkerhet
            </h2>
            <p className="text-gray-800 leading-relaxed">
              Vi använder Firebase för säker lagring av uppladdade filer.
              Firebase följer industristandarder för dataskydd, inklusive
              kryptering och åtkomstkontroller. Endast behörig personal har
              åtkomst till datan.
            </p>
          </div>

          <div className="space-y-4">
            <h2 className="text-xl font-bold text-gray-900">
              4. Dina rättigheter
            </h2>
            <ul className="list-disc pl-5 text-gray-700 space-y-2">
              <li>Begära åtkomst till dina personuppgifter.</li>
              <li>Begära att dina personuppgifter raderas när som helst.</li>
              <li>Återkalla samtycke till databehandling.</li>
            </ul>
          </div>

          <div className="space-y-4">
            <h2 className="text-xl font-bold text-gray-900">
              5. Delning av data
            </h2>
            <p className="text-gray-800 leading-relaxed">
              Din data delas inte med tredje part, såvida det inte krävs enligt
              lag eller för att uppfylla de syften som beskrivs i denna policy.
            </p>
          </div>
          <div className="space-y-4">
            <h2 className="text-xl font-bold text-gray-900">
              6. Användning av cookies
            </h2>
            <p className="text-gray-800 leading-relaxed">
              Den här webbplatsen använder cookies för att förbättra
              användarupplevelsen. När du klickar på godkänn , accepterar du att
              dina personuppgifter kan användas i enlighet med GDPR-lagar, ifall
              du skulle ladda upp ditt CV.
            </p>
          </div>

          <div className="space-y-4">
            <h2 className="text-xl font-bold text-gray-900">7. Kontakta oss</h2>
            <p className="text-gray-800 leading-relaxed">
              Om du har några frågor eller funderingar kring denna
              sekretesspolicy, vänligen kontakta oss på:
            </p>
            <address className="not-italic text-gray-700">
              <strong>Pifas AB</strong>
              <br />
              E-post:{" "}
              <a
                href="mailto:mail@pifasab.com"
                className="text-blue-500 hover:underline"
              >
                mail@pifasab.com
              </a>
              <br />
              Webbplats:{" "}
              <a
                href="https://pifasab.com"
                className="text-blue-500 hover:underline"
              >
                pifasab.com
              </a>
            </address>
          </div>

          <p className="text-sm text-gray-500">
            Senast uppdaterad: <span className="font-medium">2024/27/11</span>
          </p>
          <button
            className="text-blue-500 hover:underline mt-4"
            onClick={() => setIsExpanded(false)}
          >
            Stäng
          </button>
        </div>
      )}
    </div>
  );
};

export default PrivacyPolicy;
